<template>
  <v-container id="alerts" fluid tag="section">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <v-row justify="center">
      <v-dialog v-model="toggleDownloadModel" max-width="600">
        <template>
          <v-card>
            <v-form
              ref="addDownloadForm"
              v-model="validateAddDownload"
              lazy-validation
            >
              <v-card-title>
                <span v-if="!editDownloadMode" class="headline"
                  >Add Download</span
                >
                <span v-else class="headline">Edit Download</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row justify="center">
                    <v-menu
                      v-model="fromDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Date*"
                          readonly
                          outlined
                          dense
                          v-model="downloadDate"
                          :value="fromDateDisp"
                          :rules="dateRules"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="en-in"
                        v-model="downloadDate"
                        no-title
                        @input="fromDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="downloadName"
                      :counter="100"
                      label="Name*"
                      outlined
                      dense
                      required
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      name="input-7-1"
                      required
                      outlined
                      dense
                      v-model="downloadDesc"
                      :rules="descRules"
                      label="Description*"
                    ></v-textarea>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="downloadUrl"
                      :counter="100"
                      label="Url*"
                      outlined
                      dense
                      required
                      :rules="urlRules"
                    >
                    </v-text-field>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!validateAddDownload"
                  color="primary"
                  class="mr-4"
                  @click="addDownload"
                  v-if="!editDownloadMode"
                >
                  Save
                </v-btn>
                <v-btn
                  :disabled="!validateAddDownload"
                  color="primary"
                  class="mr-4"
                  @click="updateDownload"
                  v-else
                >
                  Update
                </v-btn>
                <v-btn color="default" @click="toggleDownloadModel = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <div class="text-right">
      <v-btn
        small
        color="primary"
        dark
        class="mr-4"
        @click="openDownloadModel(false)"
      >
        Add Download
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="downloads_data"
              :options.sync="options"
              :server-items-length="totalDownloads"
              :loading="loading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.actions="{ item }">
                <!-- 
                  <v-icon
                    small
                    v-show="item.dl_url"
                    class="mr-2"
                    title="Download Attachment"
                    @click="downloadFile(item.dl_id)"
                  >
                    mdi-cloud-download
                  </v-icon>
                -->
                <v-icon
                  small
                  class="mr-2"
                  title="Edit Download"
                  @click="openDownloadModel(true, item.dl_id)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  title="Delete Download"
                  @click="deleteDownload(item.dl_id)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- <v-pagination
        v-model="page"Download
        :total-visible="10"
      ></v-pagination> -->

            <div></div>
          </div>
          <div class="text-center pt-4 pb-4">
            <Pagination
              :isloading="isLoading"
              :startRecord="startRecord"
              :currentPage="currentPage"
              :lastPage="lastPage"
              :lastRecord="lastRecord"
              :totRecords="totRecords"
              :isCurrentPageClass="isCurrentPageClass"
              :perpage="perpage"
              :getLastPageClass="getLastPageClass"
              :totPage="totPage"
              :getPages="getPages"
              @handlePerPage="handlePerPage"
              @paginate="paginate"
              @last="last"
              @getDataByPage="getDataByPage"
              :showPerPage="showPerPage"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import DashboardCoreView from "@/views/dashboard/CompanyAutocomplete";
import StatesCoreView from "@/views/dashboard/StateAutocomplete";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "./component/Pagination.vue";
import moment from "moment";

export default {
  name: "ManageDownloads",

  components: {
    DashboardCoreView,
    StatesCoreView,
    Loading,
    Pagination,
  },

  data: () => ({
    //for loading//
    loading: false,
    isLoading: true,
    fullPage: true,
    ///
    query: "",
    toggleDownloadModel: false,
    //form
    validateAddDownload: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 100) || "Name must be less than 100 characters",
    ],
    dateRules: [(v) => !!v || "Date is required"],
    descRules: [(v) => !!v || "Description is required"],
    urlRules: [(v) => !!v || "Url is required"],
    downloadDate: null,
    downloadName: "",
    downloadDesc: "",
    downloadUrl: "",
    editDlId: "",
    //
    //pagination code
    perpage: 50,
    totRecords: null,
    page: 1,
    isLoading: true,
    fullPage: true,
    showPerPage: false,
    ///
    //////table data starts here////
    selected: [],
    options: {},
    sort: "",
    page: 1,
    downloads_data: [],
    pageCount: 0,
    totalDownloads: 0,
    // pagination: {
    //   rowsPerPage: 100,
    //   descending: false,
    //    sortable: false,
    //   page: 1
    // },
    editDownloadMode: false,
    fromDateMenu: false,
    fromDateVal: null,
    //  minDate: "2020-01-05",
    //  maxDate: "2019-08-30",
    headers: [
      {
        text: "Date",
        value: "dl_sortdate",
        width: "150px",
        sortable: false,
      },
      { text: "Name", value: "dl_name", sortable: false },
      { text: "Description", value: "dl_desc", sortable: false },
      { text: "File", value: "dl_url", sortable: false },
      { text: "Action", value: "actions", width: "150px", sortable: false },
    ],
    ////// table data ends here
  }),

  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
  },
  watch: {
    "$store.state.Rawdata.current_active_module": function (o) {
      if (o) {
        this.getTempData(this.page);
      }
    },
    page(obj) {
      this.downloads_data = [];
      this.page = obj;
      //this.getTempData(obj)
    },
  },
  mounted() {
    //this.getTempData(this.pagination.page)
  },

  methods: {
    //pagination methods
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getTempData(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getTempData(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getTempData(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getTempData(this.page);
    },
    /////
    getTempData(page = "", query = "") {
      let view = this;
      view.isLoading = true;
      let manage_downloads_url = this.$url("MANAGEDOWNLOADS");

      const payload = JSON.stringify({ "page_number": view.page, "page_size": 20 })
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        // .post(manage_downloads_url + "?q=" + view.query + "&page=" + view.page)
        .post(manage_downloads_url, payload,  {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        .then((response) => {
          view.isLoading = false;
          console.log(response.data.payload);
          view.downloads_data = response.data.data;
          view.totalDownloads = response.data.total_records;
          view.totRecords = response.data.total_records;
          view.pageCount = response.data.total_pages;
        })
        .catch((error) => {
          console.error(error);
          view.isLoading = false;
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        });
    },
    openDownloadModel(editMode, dl_id = 0) {
      this.toggleDownloadModel = true;
      if (editMode) {
        this.editDownloadMode = true;
      } else {
        this.editDownloadMode = false;
        this.downloadDate = null;
        this.downloadName = "";
        this.downloadDesc = "";
        this.downloadUrl = "";
      }
      if (dl_id != 0) {
        this.getClientDownload(dl_id);
      }
    },
    addDownload() {
      let view = this;
      if (view.$refs.addDownloadForm.validate()) {
        const payload = JSON.stringify({ 
          "dl_name": this.downloadName,
          "dl_desc": this.downloadDesc,
          "dl_url": this.downloadUrl,
          "dl_sortdate": this.downloadDate,
        })
        
        let save_downloade_url = this.$url("SAVEDOWNLOAD");
        view.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "add", vueScope: view })
          .post(save_downloade_url, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            view.isLoading = false;
            view.toggleDownloadModel = false;
            view.$swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
            });
            view.getTempData(view.page);
          })
          .catch((error) => {
            console.error(error);
            view.isLoading = false;
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Something went wrong!",
            });
          });
      }
    },
    updateDownload() {
      let view = this;
      if (view.$refs.addDownloadForm.validate()) {
        // let formData = new FormData();
        // view.editDlId;
        // formData.append("download_id", this.editDlId);
        // formData.append("name", this.downloadName);
        // formData.append("date", this.downloadDate);
        // formData.append("desc", this.downloadDesc);
        // formData.append("url", this.downloadUrl);
        const payload = JSON.stringify({ 
          "dl_id": this.editDlId,
          "dl_name": this.downloadName,
          "dl_desc": this.downloadDesc,
          "dl_url": this.downloadUrl,
          "dl_sortdate": this.downloadDate,
        })
        
        
        let manage_downloads_url = this.$url("SAVEDOWNLOAD");
        view.isLoading = true;
        this.$fetch({ requiresAuth: true, operation: "edit", vueScope: view })
          .put(manage_downloads_url, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            view.isLoading = false;
            view.toggleDownloadModel = false;
            view.$swal.fire({
              icon: "success",
              title: "Success",
              text: response.data.message,
            });
            view.getTempData(view.page);
          })
          .catch((error) => {
            console.error(error);
            view.isLoading = false;
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Something went wrong!",
            });
          });
      }
    },
    getClientDownload(dl_id) {
      let view = this;
      let get_download_url = this.$url("GETDOWNLOAD")+`/${dl_id}`;
      view.isLoading = true;
     
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(get_download_url)
        .then((response) => {
          view.isLoading = false;
          view.editDlId = response.data.data.dl_id;
          view.downloadName = response.data.data.dl_name;
          view.downloadDesc = response.data.data.dl_desc;
          view.downloadDate = moment(
            response.data.data.dl_sortdate
          ).isValid()
            ? moment(response.data.data.dl_sortdate).format(
                "YYYY-MM-DD"
              )
            : response.data.data.dl_sortdate;
          view.downloadUrl = response.data.data.dl_url;
        })
        .catch((error) => {
          console.error(error);
          view.isLoading = false;
          view.$swal.fire({
            icon: "error",
            title: "Error",
            text: "Something went wrong!",
          });
        });

      // this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
      //   .get(get_download_url + "?dl_id=" + dl_id)
      //   .then((response) => {
      //     view.isLoading = false;
      //     view.editDlId = response.data.payload.data.dl_id;
      //     view.downloadName = response.data.payload.data.dl_name;
      //     view.downloadDesc = response.data.payload.data.dl_desc;
      //     view.downloadDate = moment(
      //       response.data.payload.data.dl_sortdate
      //     ).isValid()
      //       ? moment(response.data.payload.data.dl_sortdate).format(
      //           "YYYY-MM-DD"
      //         )
      //       : response.data.payload.data.dl_sortdate;
      //     view.editFileName = response.data.payload.data.fid_filename;
      //   });
    },
    downloadFile(fileID) {
      let view = this;
      view.isLoading = true;
      let download_file_uploads_url = this.$url("DOWNLOADCLIENTREPORTFILE");
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(download_file_uploads_url + "?fileID=" + fileID)
        .then((response) => {
          view.isLoading = false;
          console.log(response.data.payload.url);
          if (response.data.statusCode === 200) {
            let file_url = response.data.payload.url;
            window.open(file_url);
          }
        });
    },
    deleteDownload(dl_id) {
      let view = this;
      let delete_item_url = this.$url("SAVEDOWNLOAD");
      const payload = JSON.stringify({ 
        dl_id
      })
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will delete this item",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            view.isLoading = true;
            this.$fetch({
              requiresAuth: true,
              operation: "delete",
              vueScope: view,
            })
              .delete(delete_item_url, {
                data: {
                  dl_id
                },
                headers: {
                  "Content-Type": "application/json",
                }})
              .then((response) => {
                view.isLoading = false;
                if (response.data.status_code === 200) {
                  console.log(response.data.message);
                  view.$swal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.data.message,
                  });
                  view.getTempData(view.page);
                }
              })
              .catch((error) => {
                console.error(error);
                view.isLoading = false;
                view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
              });
          } else if (result.isDenied) {
            view.$swal.fire("Cancelled", "File was not deleted!", "error");
          }
        });
    },
  },
};
</script>
